import {
  CheckOutlined,
  PauseOutlined,
  UndoOutlined
} from "@ant-design/icons";
import { Avatar, Button, Card, Col, Flex, Row } from "antd";
import React from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import styles from "./activerecordingview.module.scss";

type ActiveRecordingViewProps = {
  isPaused: boolean;
  recordingTime: number;
  handleStopRecording: () => void;
  handleResumeRecording: () => void;
  handleSubmitAudio: () => void;
  deleteRecording: () => void;
};

const ActiveRecordingView = ({
  isPaused,
  recordingTime,
  handleStopRecording,
  handleResumeRecording,
  handleSubmitAudio,
  deleteRecording,
}: ActiveRecordingViewProps) => {
  const componentName = "ActiveRecordingView";

  const { isSubmissionInProgress } = useAppSelector((state) => state.appState);
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const { clients } = useAppSelector((state) => state.client);

  const client = React.useMemo(() => {
    return clients.find((client) => client.id === recordingDetail?.client_id);
  }, [recordingDetail, clients]);
  return (
    <Card className={styles[`${componentName}__container-right-container`]}>
      <Row>
        {recordingDetail && recordingDetail.client_id && client && (
          <Col className={styles[`${componentName}__container-right-user`]}>
            <span>
              <Avatar
                style={{
                  backgroundColor: getRandomAvatarColor(client.name),
                }}
                size={"small"}
              >
                {client.name[0].toUpperCase()}
              </Avatar>{" "}
              &nbsp;
              {client.name}
            </span>
          </Col>
        )}
        <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
          <Card
            className={`${styles[`${componentName}__container-right-recording-card`]}`}
          >
            {!isPaused && (
              <div className={styles[`${componentName}__modal-logo`]}>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10.5" cy="10" r="10" fill="#FEE2E2" />
                  <circle
                    cx="10.5002"
                    cy="9.99968"
                    r="5.29412"
                    fill="#EF4444"
                  />
                </svg>
              </div>
            )}
            {isPaused && (
              <Flex justify="center">
                <PauseOutlined
                  style={{
                    fontSize: "25px",
                    color: "var(--grey-text)",
                    margin: "10px 0px",
                  }}
                />
              </Flex>
            )}
            <Flex
              className={styles[`${componentName}__card-right-text`]}
              justify="center"
              vertical
              gap={10}
            >
              <Flex
                justify="center"
                align="center"
                gap={10}
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                {isPaused ? "Captured" : "Capturing"} session
              </Flex>

              <span className={styles[`${componentName}__modal-sub-text`]}>
                {Math.floor(recordingTime / 60)}:
                {String(recordingTime % 60).padStart(2, "0")} (max: 120 mins)
              </span>
            </Flex>
          </Card>
        </Col>
        <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
          {!isPaused && (
            <Row gutter={[10, 10]}>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  type="default"
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  }`}
                  icon={<PauseOutlined />}
                  onClick={handleStopRecording}
                >
                  Pause
                </Button>
              </Col>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  }`}
                  onClick={handleStopRecording}
                >
                  End Session
                </Button>
              </Col>
            </Row>
          )}
          {isPaused && (
            <Row gutter={[10, 10]}>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  type="default"
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  } `}
                  icon={<UndoOutlined />}
                  onClick={handleResumeRecording}
                >
                  Resume
                </Button>
              </Col>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  loading={isSubmissionInProgress}
                  disabled={isSubmissionInProgress}
                  type="primary"
                  icon={<CheckOutlined />}
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  }`}
                  onClick={handleSubmitAudio}
                >
                  Submit Audio
                </Button>
              </Col>
            </Row>
          )}
          {isPaused && (
            <Flex
              gap={10}
              justify="center"
              className={`${styles[`${componentName}__modal-row-secondary`]}`}
            >
              <span onClick={deleteRecording}>Cancel recording</span>
            </Flex>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ActiveRecordingView;
