import { AudioOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux-hooks";
import { setCreateModalState } from "../../slices/appStateSlice";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import { trackEvent } from "../../utils/analytics";
import { getAnalyticsKeyOnTheBasisOfType } from "../../utils/datamanipulation.utils";
import styles from "./recordcta.module.scss";

type RecordCTAProps = {
  captureRef?: React.RefObject<HTMLDivElement>;
};

const RecordCTA = ({ captureRef }: RecordCTAProps) => {
  const dispatch = useDispatch();

  const componentName = "RecordCTA";

  const { uploadInProgress, isDemoPath, selectedClient } = useAppSelector((state) => state.appState);
  const { recordingDetail } = useAppSelector((state) => state.recordings);

  const handleButtonClick = (type: "audio" | "dictate" | "upload" | "write") => {
      if (uploadInProgress) {
        message.warning("Please wait for the current upload to finish.");
        return;
      }
      if (isDemoPath) {
        trackEvent(getAnalyticsKeyOnTheBasisOfType(type), {
          type,
        });
      }
      dispatch(
        setRecordingDetail({
          ...recordingDetail,
          modality_type: type,
          client_id : selectedClient?.id
        }),
      );
      dispatch(setCreateModalState(true));
  };

  return (
    <>
      <Row
        gutter={[10, 10]}
        ref={captureRef}
        className={styles[`${componentName}__container-right-container`]}
      >
        <Col
          span={24}
          className={
            styles[`${componentName}__container-right-header-container`]
          }
        >
          <Typography
            className={styles[`${componentName}__container-right-header`]}
          >
            Generate Note
          </Typography>
        </Col>
        <Col span={12} md={24} lg={24}>
          <Card
            ref={captureRef}
            className={styles[`${componentName}__container-right-card`]}
            onClick={() => {
              handleButtonClick("audio");
            }}
          >
            <svg
              className={styles[`${componentName}__container-right-card-logo`]}
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 6.25V13.75M6 3.75V16.25M10.5 1.25V18.75M15 3.75V16.25M19.5 6.25V13.75"
                stroke="#3B82F6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Typography
              className={styles[`${componentName}__container-right-card-title`]}
            >
              Capture Session
            </Typography>
            <Typography
              className={
                styles[`${componentName}__container-right-card-description`]
              }
            >
              Recommended for live sessions
            </Typography>
          </Card>
        </Col>
        <Col span={12} md={24} lg={24} xl={8} xxl={8}>
          <Card
            className={styles[`${componentName}__container-right-card`]}
            onClick={() => handleButtonClick("dictate")}
          >
            <AudioOutlined
              className={
                styles[`${componentName}__container-right-card-seconrdary-logo`]
              }
            />
            <Typography
              className={`${styles[`${componentName}__container-right-card-title`]} ${
                styles[`${componentName}__container-right-card-title-secondary`]
              }`}
            >
              Dictate
            </Typography>
            <Typography
              className={
                styles[
                  `${componentName}__container-right-card-secondary-description`
                ]
              }
            >
              Recommended for post-session dictation
            </Typography>
          </Card>
        </Col>
        <Col span={12} md={24} lg={24} xl={8} xxl={8}>
          <Card
            className={styles[`${componentName}__container-right-card`]}
            onClick={() => {
              handleButtonClick("upload");
            }}
          >
            <UploadOutlined
              className={
                styles[`${componentName}__container-right-card-seconrdary-logo`]
              }
            />
            <Typography
              className={`${styles[`${componentName}__container-right-card-title`]} ${
                styles[`${componentName}__container-right-card-title-secondary`]
              }`}
            >
              Upload
            </Typography>
            <Typography
              className={
                styles[
                  `${componentName}__container-right-card-secondary-description`
                ]
              }
            >
              For recorded audio clip of sessions
            </Typography>
          </Card>
        </Col>
        <Col span={12} md={24} lg={24} xl={8} xxl={8}>
          <Card
            className={styles[`${componentName}__container-right-card`]}
            onClick={() => {
              handleButtonClick("write");
            }}
          >
            <EditOutlined
              className={
                styles[`${componentName}__container-right-card-seconrdary-logo`]
              }
            />
            <Typography
              className={`${styles[`${componentName}__container-right-card-title`]} ${
                styles[`${componentName}__container-right-card-title-secondary`]
              }`}
            >
              Describe
            </Typography>
            <Typography
              className={
                styles[
                  `${componentName}__container-right-card-secondary-description`
                ]
              }
            >
              For descriptive drafts, notes, writings
            </Typography>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RecordCTA;
