import { ExportOutlined } from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  Flex,
  Layout,
  type MenuProps,
  Typography
} from "antd";
import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import AvatarImage from "../assets/avatar/avatar.svg";
import SOLLogo from "../assets/images/sol.jpg";
import GWULLogo from "../assets/images/gwul.png";
import ChangePassword from "../components/ChangePassword/ChangePassword.component";
import PurchaseModal from "../components/PurchaseModal/Purchase.component";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { resetBasicUserInfo } from "../slices/authSlice";
import { setShowPaymentModal } from "../slices/userSlice";
import { EventType, trackEvent } from "../utils/analytics";
import { getFirstLetterOfNameAndSurname } from "../utils/datamanipulation.utils";
import { setNotesViewVisible, setSelectedClient } from "../slices/appStateSlice";
import { getRandomAvatarColor } from "../utils/ui.utils";
import styles from "./layout.module.scss";
import { isFromSOL, isFromGWUL } from "../domain/groups.domain";
import { setNotesDetails } from "../slices/notesSlice";

const { Header, Content } = Layout;

const AuthLayout = () => {
  const component = "layout";
  const userData = useAppSelector((state) => state.user.data);
  const { userInfo } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const [openChangePasswordModal, setOpenChangePasswordModal] =
    React.useState<boolean>(false);
  const isHeadlessHeader = location.pathname === "/onboarding";
  const onlyHeader = location.pathname === "/privacy" || location.pathname === "/terms";

  const navigateToSubscription = () => {
    trackEvent(EventType.SUBSCRIBE_BUTTON);
    dispatch(setShowPaymentModal(true));
  };

  const logout = async () => {
    trackEvent(EventType.LOGOUT);
    localStorage.removeItem("token");
    await axiosInstance.post("/rest-auth/logout/", {}).catch((error) => {
      console.log(error);
    });
    dispatch(resetBasicUserInfo());
    window.location.href = "/login";
  };

  const navigateToHome = () => {
    navigation("/home");
    dispatch(setSelectedClient(null));
    dispatch(setNotesDetails(null));
    dispatch(setNotesViewVisible(false));
  };

  const contactUs = () => {
    trackEvent(EventType.CONTACT_US);
    if (userInfo.enable_custom_templates) {
      // this means the user is from SOL
      window.open("https://tally.so/r/3jLbZa", "_blank");
    } else {
      window.open("https://tally.so/r/wg5QxK", "_blank");
    }
  };

  const openAnalyticsPage = () => {
    navigation("/analytics");
  };

  const shouldRenderLogo = isFromSOL(userInfo) || isFromGWUL(userInfo);

  const renderLogo = () => {
    const logo = isFromSOL(userInfo) ? SOLLogo : GWULLogo;

    return (
      <Flex
        style={{
          padding: 4,
          backgroundColor: "#F9FAFB",
          height: "max-content",
          width: "max-content",
          borderRadius: 4,
        }}
      >
        <img
          height={40}
          width="auto"
          alt="logo"
          src={logo}
          style={{ borderRadius: 4 }}
        />
      </Flex>
    );
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div style={{ color: "#000" }}>{userData?.email || "User"}</div>,
    },
    {
      key: "2",
      label: (
        <div style={{ color: "#000" }} onClick={navigateToSubscription}>
          Subscription
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={openAnalyticsPage} style={{ color: "#000" }}>
          Analytics
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div onClick={contactUs} style={{ color: "#000" }}>
          Contact us
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <a
          href="/pdf/client_consent_form.pdf"
          target="_blank"
          style={{ color: "#000" }}
          rel="noreferrer"
        >
          Consent Form <ExportOutlined />
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a
          href={
            userInfo.enable_custom_templates
              ? "https://www.youtube.com/playlist?list=PL9lb9UfUiaJwSF0u5fBx22xb_CwFExyKu"
              : "https://www.youtube.com/playlist?list=PL9lb9UfUiaJwUUCOBLgiznYWj8NT-7jD2"
          }
          target="_blank"
          style={{ color: "#000" }}
          rel="noreferrer"
        >
          Tutorial <ExportOutlined />
        </a>
      ),
    },
    {
      key: "7",
      label: (
        <div onClick={logout} style={{ color: "red" }}>
          Logout
        </div>
      ),
    },
  ];
  const token = localStorage.getItem("token");

  const filteredItems: MenuProps["items"] = items.filter((item) => {
    // Don't show Subscription modal if user is already paying
    if (item?.key === "2" && userInfo.is_paying) {
      return false;
    }
    // This is to filter out Analytics option from the menu if the user is got a group admin
    if (item?.key === "3" && !userInfo.enable_analytics) {
      return false;
    }

    return true;
  });

  if (!token) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <Layout className={`${styles[`${component}__container`]}`}>
      {openChangePasswordModal && (
        <ChangePassword
          show={openChangePasswordModal}
          onClose={() => {
            setOpenChangePasswordModal(false);
          }}
        />
      )}
      <Header
        className={
          isHeadlessHeader
            ? `${styles[`${component}__header-headless`]}`
            : `${styles[`${component}__header`]}`
        }
      >
        <svg
          style={{ cursor: "pointer", marginRight: "5px" }}
          onClick={navigateToHome}
          width="74"
          height="24"
          viewBox="0 0 74 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.27635 10.5904V18.506H0V4.62651H4.06647V7.1747H4.21513C4.51246 6.3253 5.01968 5.65964 5.73678 5.17771C6.45387 4.68976 7.30798 4.44578 8.29909 4.44578C9.24356 4.44578 10.0627 4.66566 10.7565 5.10542C11.4561 5.53916 11.9983 6.14759 12.383 6.93072C12.7737 7.70783 12.966 8.61747 12.9602 9.65964V18.506H8.68387V10.5271C8.6897 9.75603 8.50023 9.15361 8.11544 8.71988C7.73649 8.28615 7.20887 8.06928 6.53258 8.06928C6.08366 8.06928 5.68722 8.17169 5.34325 8.37651C5.00511 8.5753 4.74275 8.86145 4.55619 9.23494C4.37546 9.60843 4.28218 10.0602 4.27635 10.5904Z"
            fill="#6B7280"
          />
          <path
            d="M23.7817 12.5151V4.62651H28.0493V18.506H23.974V15.9217H23.8341C23.5368 16.7711 23.0296 17.4458 22.3125 17.9458C21.6012 18.4398 20.7413 18.6867 19.7327 18.6867C18.8174 18.6867 18.0128 18.4699 17.319 18.0361C16.6253 17.6024 16.086 16.997 15.7012 16.2199C15.3164 15.4367 15.1211 14.5211 15.1153 13.4729V4.62651H19.3916V12.6054C19.3974 13.3584 19.5898 13.9518 19.9688 14.3855C20.3477 14.8193 20.8637 15.0361 21.5167 15.0361C21.9423 15.0361 22.3241 14.9398 22.6623 14.747C23.0063 14.5482 23.2774 14.262 23.4756 13.8886C23.6796 13.509 23.7817 13.0512 23.7817 12.5151Z"
            fill="#6B7280"
          />
          <path
            d="M35.1846 18.7048C34.1935 18.7048 33.2928 18.4398 32.4824 17.9096C31.672 17.3795 31.0249 16.5843 30.541 15.5241C30.0571 14.4639 29.8151 13.1476 29.8151 11.5753C29.8151 9.94277 30.0658 8.5994 30.5672 7.54518C31.0686 6.49096 31.7245 5.71084 32.5349 5.20482C33.3511 4.6988 34.2285 4.44578 35.1671 4.44578C35.8726 4.44578 36.476 4.57229 36.9774 4.8253C37.4788 5.07229 37.8927 5.39157 38.2192 5.78313C38.5457 6.1747 38.7934 6.58133 38.9625 7.00301H39.05V0H43.3263V18.506H39.0937V16.256H38.9625C38.7818 16.6837 38.5253 17.0843 38.1929 17.4578C37.8606 17.8313 37.4438 18.1325 36.9424 18.3614C36.4468 18.5904 35.8609 18.7048 35.1846 18.7048ZM36.6713 15.262C37.1902 15.262 37.6333 15.1114 38.0005 14.8102C38.3678 14.503 38.6506 14.0723 38.8488 13.5181C39.047 12.9639 39.1462 12.3133 39.1462 11.5663C39.1462 10.8072 39.047 10.1536 38.8488 9.60542C38.6564 9.05723 38.3737 8.63554 38.0005 8.34036C37.6333 8.04518 37.1902 7.89759 36.6713 7.89759C36.1408 7.89759 35.6918 8.04819 35.3246 8.3494C34.9573 8.6506 34.6774 9.0753 34.485 9.6235C34.2985 10.1717 34.2052 10.8193 34.2052 11.5663C34.2052 12.3133 34.3014 12.9639 34.4938 13.5181C34.6862 14.0723 34.9631 14.503 35.3246 14.8102C35.6918 15.1114 36.1408 15.262 36.6713 15.262Z"
            fill="#6B7280"
          />
          <path
            d="M51.7997 24C50.5229 24 49.4298 23.8163 48.5203 23.4488C47.6108 23.0813 46.8966 22.5783 46.3777 21.9398C45.8589 21.3012 45.5469 20.5783 45.442 19.7711L49.491 19.5361C49.5668 19.8193 49.7067 20.0663 49.9107 20.2771C50.1206 20.488 50.3917 20.6506 50.724 20.7651C51.0622 20.8795 51.4586 20.9367 51.9134 20.9367C52.6305 20.9367 53.2222 20.756 53.6886 20.3946C54.1609 20.0392 54.397 19.4157 54.397 18.5241V16.0843H54.2483C54.0792 16.512 53.8227 16.8946 53.4787 17.2319C53.1348 17.5693 52.7063 17.8343 52.1932 18.0271C51.686 18.2199 51.103 18.3163 50.4442 18.3163C49.4647 18.3163 48.5669 18.0813 47.7507 17.6114C46.9403 17.1355 46.2903 16.4006 45.8006 15.4066C45.3167 14.4066 45.0747 13.1205 45.0747 11.5482C45.0747 9.92771 45.3254 8.59337 45.8268 7.54518C46.3282 6.49096 46.9841 5.71084 47.7944 5.20482C48.6106 4.6988 49.4881 4.44578 50.4267 4.44578C51.1321 4.44578 51.7356 4.57229 52.2369 4.8253C52.7442 5.07229 53.161 5.39157 53.4875 5.78313C53.814 6.1747 54.0617 6.58133 54.2308 7.00301H54.3532V4.62651H58.6121V18.5602C58.6121 19.7349 58.3264 20.7259 57.7551 21.5331C57.1837 22.3404 56.385 22.9518 55.3589 23.3675C54.3328 23.7892 53.1464 24 51.7997 24ZM51.9309 15.0994C52.4497 15.0994 52.8928 14.9578 53.2601 14.6747C53.6274 14.3916 53.9102 13.9849 54.1084 13.4548C54.3066 12.9247 54.4057 12.2892 54.4057 11.5482C54.4057 10.7952 54.3066 10.1476 54.1084 9.60542C53.916 9.05723 53.6332 8.63554 53.2601 8.34036C52.8928 8.04518 52.4497 7.89759 51.9309 7.89759C51.4003 7.89759 50.9514 8.04819 50.5841 8.3494C50.2168 8.6506 49.937 9.0753 49.7446 9.6235C49.558 10.1657 49.4647 10.8072 49.4647 11.5482C49.4647 12.2892 49.5609 12.9247 49.7533 13.4548C49.9457 13.9849 50.2227 14.3916 50.5841 14.6747C50.9514 14.9578 51.4003 15.0994 51.9309 15.0994Z"
            fill="#6B7280"
          />
          <path
            d="M67.1248 18.7681C65.7198 18.7681 64.5071 18.4819 63.4869 17.9096C62.4725 17.3313 61.6912 16.509 61.1432 15.4428C60.601 14.3705 60.3299 13.0964 60.3299 11.6205C60.3299 10.1867 60.6039 8.93374 61.1519 7.86145C61.7 6.78313 62.4725 5.94578 63.4694 5.3494C64.4663 4.74699 65.6411 4.44578 66.9937 4.44578C67.9498 4.44578 68.8243 4.5994 69.6172 4.90663C70.4101 5.21386 71.0951 5.66867 71.6723 6.27108C72.2495 6.87349 72.6984 7.61747 73.019 8.50301C73.3397 9.38253 73.5 10.3916 73.5 11.5301V12.6325H61.8253V10.0663H69.521C69.5152 9.59639 69.4073 9.17771 69.1974 8.81024C68.9875 8.44277 68.699 8.15663 68.3317 7.95181C67.9702 7.74096 67.5533 7.63554 67.0811 7.63554C66.603 7.63554 66.1745 7.74699 65.7956 7.96988C65.4166 8.18675 65.1164 8.48494 64.8948 8.86446C64.6733 9.23795 64.5567 9.66265 64.545 10.1386V12.75C64.545 13.3163 64.6529 13.8133 64.8686 14.241C65.0843 14.6627 65.3904 14.991 65.7868 15.2259C66.1833 15.4608 66.6555 15.5783 67.2035 15.5783C67.5825 15.5783 67.9265 15.5241 68.2355 15.4157C68.5444 15.3072 68.8097 15.1476 69.0313 14.9367C69.2528 14.7259 69.419 14.4669 69.5297 14.1596L73.4563 14.2771C73.293 15.1867 72.9345 15.9789 72.3806 16.6536C71.8326 17.3223 71.1126 17.8434 70.2206 18.2169C69.3286 18.5843 68.2967 18.7681 67.1248 18.7681Z"
            fill="#6B7280"
          />
        </svg>
        {!isHeadlessHeader && !onlyHeader && (
          <Flex align="center" gap={15} justify="center">
            <Flex align="center">
              {shouldRenderLogo ? (
                renderLogo()
              ) : (
                <>
                  {userInfo.tier === 1 && (
                    <Typography style={{ color: "#000", fontWeight: 600 }}>
                      Pro Tier
                    </Typography>
                  )}
                  {userInfo.tier === 2 && (
                    <Typography style={{ color: "#000", fontWeight: 600 }}>
                      {userInfo.group_name ? userInfo.group_name : "Group Tier"}
                    </Typography>
                  )}
                </>
              )}
            </Flex>
            {!onlyHeader && (
              <Dropdown menu={{ items: filteredItems }} placement="bottom">
                {isFromSOL(userInfo) ? (
                  <Avatar
                    size={40}
                    style={{
                      backgroundColor: getRandomAvatarColor(
                        userData?.name ?? ""
                      ),
                      cursor: "pointer",
                    }}
                  >
                    {getFirstLetterOfNameAndSurname(userData?.name ?? "")}
                  </Avatar>
                ) : (
                  <Avatar
                    style={{ cursor: "pointer" }}
                    src={AvatarImage}
                  ></Avatar>
                )}
              </Dropdown>
            )}
          </Flex>
        )}
      </Header>
      <Content className={`${styles[`${component}__content`]}`}>
        <Outlet />
      </Content>
      <PurchaseModal />
    </Layout>
  );
};

export default AuthLayout;
