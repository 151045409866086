import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const isProd = process.env.REACT_APP_SERVER_TYPE === "prod";
const postHogKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string;

if (isProd) {
  posthog.init(postHogKey, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    disable_session_recording: false,
  });
}

Sentry.init({
  dsn: "https://068f74177d2925c91249ba816ca09ab6@o4506998898688000.ingest.us.sentry.io/4507005146759168",
  environment: process.env.REACT_APP_SERVER_TYPE,
  release: process.env.REACT_APP_SENTRY_RELEASE, // This is set by Sentry
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^\//],
  replaysSessionSampleRate: 0.0, // Do not log non-error replays
  replaysOnErrorSampleRate: isProd ? 1.0 : 0.0, // only replay error sessions on production
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <PostHogProvider client={posthog}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </PostHogProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
