import { Collapse, Flex, Steps } from "antd";
import styles from "./tutorial.module.scss";
import HowToTell from "./HowToTell.component";

const Tutorial = () => {
  const component = "tutorial";

  return (
    <Flex vertical className={styles[`${component}__container`]}>
      <Collapse
        defaultActiveKey={["1"]}
        className={styles[`${component}__container-tutorial`]}
        items={[
          {
            key: "1",
            label: "How To in 3 steps",
            children: (
              <Steps
                current={-1}
                direction="vertical"
                items={[
                  {
                    title: "Create Note",
                    description:
                      "Choose a mode to create a note: click on Capture Session, Dictate, Upload, or the Describe button.",
                  },
                  {
                    title: "Fill in note information",
                    description:
                      "Fill in the details of the note when the drawer opens.",
                  },
                  {
                    title: "Generate and Review Note",
                    description:
                      "Wait until the note generates, and then proceed to review the note.",
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <HowToTell />
    </Flex>
  );
};

export default Tutorial;
