import { TNotes } from "../types/index.type";


export const cptCodeOptions = [
  { label: "---", value: null },
  {
    label: <span style={{ fontWeight: "bold" }}>Psychotherapy</span>,
    title: "Psychotherapy",
    options: [
      {
        label: "90832",
        desc: "(16-37 minutes psychotherapy session)",
        value: 0,
      },
      {
        label: "90834",
        desc: "(38-52 minutes psychotherapy session)",
        value: 1,
      },
      { label: "90837", desc: "(53+ minutes psychotherapy session)", value: 2 },
      {
        label: "90839",
        desc: "(Psychotherapy for crisis situations, first 60 minutes)",
        value: 16,
      },
      {
        label: "90846",
        desc: "(Family Psychotherapy without patient present, 50 minutes)",
        value: 17,
      },
      {
        label: "90847",
        desc: "(Family Psychotherapy with patient present, 50 minutes)",
        value: 18,
      },
      {
        label: "90853",
        desc: "(Group Psychotherapy, not family-related)",
        value: 19,
      },
      {
        label: "90791",
        desc: "(Psychiatric Diagnostic Evaluation)",
        value: 11,
      },
    ],
  },
  {
    label: <span style={{ fontWeight: "bold" }}>Psychiatry</span>,
    title: "Psychiatry",
    options: [
      { label: "99202", desc: "(New patient, 15-29 minutes)", value: 3 },
      { label: "99203", desc: "(New patient, 30-44 minutes)", value: 4 },
      { label: "99204", desc: "(New patient, 45-59 minutes)", value: 5 },
      { label: "99205", desc: "(New patient, 60-74 minutes)", value: 6 },
      {
        label: "99212",
        desc: "(Established Patient, 10-19 minutes)",
        value: 7,
      },
      {
        label: "99213",
        desc: "(Established Patient, 20-29 minutes)",
        value: 8,
      },
      {
        label: "99214",
        desc: "(Established Patient, 30-39 minutes)",
        value: 9,
      },
      {
        label: "99215",
        desc: "(Established Patient Visit, 40-54 minutes)",
        value: 10,
      },
      {
        label: "90791",
        desc: "(Psychiatric Diagnostic Evaluation)",
        value: 11,
      },
      {
        label: "90792",
        desc: "(Psychiatric Diagnostic Evaluation Including Medical Services)",
        value: 12,
      },
    ],
  },
];

export const cptAddOnCodeOptions = [
  { label: "---", value: null },
  {
    label: <span style={{ fontWeight: "bold" }}>Add-ons</span>,
    title: "Psychotherapy Add-ons",
    options: [
      {
        label: "90833",
        desc: "Psychotherapy, 16-37 minutes",
        value: 20,
      },
      {
        label: "90836",
        desc: "Psychotherapy, 38-52 minutes",
        value: 21,
      },
      {
        label: "90838",
        desc: "Psychotherapy, 53 minutes or more",
        value: 22,
      },
    ],
  },
];

const cptCodesByPsychotherapyMinutes = [
  {
    id: 90833,
    label: "90833: Psychotherapy, 16-37 minutes",
    min: 16,
    max: 37,
    value: 20
  },
  {
    id: 90836,
    label: "90836: Psychotherapy, 38-52 minutes",
    min: 38,
    max: 52,
    value: 21
  },
  {
    id: 90838,
    label: "90838: Psychotherapy, 53 minutes or more",
    min: 53,
    max: Number.POSITIVE_INFINITY, // Represents 53 minutes or more
    value: 22
  },
];

export const pyschiatryBaseCodes = () => {
  const psychiatryOptions = cptCodeOptions.find(
    (option) => option.title === "Psychiatry"
  )?.options;

  return psychiatryOptions?.map((opt) => opt.value);
};

export const shouldShowAddonDropdown = (duration_type: number | undefined) => {
  if (!duration_type) {
    return false;
  }
  return pyschiatryBaseCodes()?.includes(duration_type);
};

export function showAddonCptCode(
  recordingDetails: TNotes,
  psychoTherapyMinutes: number
): boolean | undefined {
  return (
    recordingDetails?.duration_addon_type !== null || psychoTherapyMinutes >= 16
  );
}

export function getCptCodeLabelByPsychotherapyMinutes(
  psychotherapyMinutes: number,
  recordingDetails?: TNotes
): string | undefined {
  if (recordingDetails?.duration_addon_type) {
    return cptCodesByPsychotherapyMinutes.find(
      (option) => option.value === recordingDetails.duration_addon_type
    )?.label;
  }
  const cptCode = cptCodesByPsychotherapyMinutes.find(
    (option) =>
      psychotherapyMinutes >= option.min && psychotherapyMinutes <= option.max,
  );

  return cptCode?.label;
}

export function getCptCodeByPsychotherapyMinutes(
  psychotherapyMinutes: number,
): number | undefined {
  const cptCode = cptCodesByPsychotherapyMinutes.find(
    (option) =>
      psychotherapyMinutes >= option.min && psychotherapyMinutes <= option.max,
  );

  return cptCode?.id;
}

export function totalTimeSpentOnPsychotherapy(text: string): number {
  // This is a hack to get the psychotherapy minutes from the note
  // These needs to be fixed on the backend - this is fixed on the backend now but using this handle the existing notes with add-ons
  const pattern = /Total Time Spent on Psychotherapy:\s*(\d+)\s*minutes?/i;

  const match = text.match(pattern);

  if (match) {
    return Number.parseInt(match[1], 10);
  }
  return 0;
}

export function extractPsychotherapyExplanation(input: string): string {
  // This is required because of the bad data i.e, for the notes that were created when the backend was saving the explanation on audiofile object
  const startIndex = input.indexOf("Psychotherapy Employed");

  if (startIndex === -1) {
    return "";
  }

  const contentStart = input.indexOf("\n", startIndex) + 1;

  const nextSection = input.indexOf("\n\n", contentStart);
  const endIndex = nextSection !== -1 ? nextSection : input.length;

  return input.slice(contentStart, endIndex).trim();
}
