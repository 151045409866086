import { Badge } from "antd";

type Props = {
  note_type?: number | null | undefined;
};

export default function NoteTypeBadge({ note_type }: Props) {
  if (note_type === 0)
    return <Badge count={"SOAP"} showZero className={`badge-soap`} />;
  else if (note_type === 1)
    return <Badge count={"Intake"} showZero className={`badge-intake`} />;
  else if (note_type === 2)
    return <Badge count={"DAP"} showZero className={`badge-dap`} />;
  else if (note_type === 3)
    return <Badge count={"EMDR"} showZero className={`badge-emdr`} />;
  else if (note_type === 4)
    return <Badge count={"EAP Intake"} showZero className={`badge-intake`} />;
  else if (note_type === 5)
    return (
      <Badge count={"Psychiatric Intake"} showZero className={`badge-soap`} />
    );
  else if (note_type === 6)
    return (
      <Badge count={"Psychiatric Intake"} showZero className={`badge-soap`} />
    );
  else if (note_type === 7)
    return (
      <Badge
        count={"Psychiatric Follow-up (SOAP)"}
        showZero
        className={`badge-soap`}
      />
    );
  else if (note_type === 8)
    return <Badge count={"Therapy Intake"} showZero className={`badge-soap`} />;
  else if (note_type === 9)
    return (
      <Badge
        count={"Therapy Follow-up (SOAP)"}
        showZero
        className={`badge-soap`}
      />
    );
  else if (note_type === 10)
    return (
      <Badge
        count={"Therapy Follow-up (DAP)"}
        showZero
        className={`badge-soap`}
      />
    );
  else if (note_type === 11)
    return (
      <Badge count={"Psychiatric Follow-up (SOAP)"} showZero className={`badge-emdr`} />
    );
  else return <></>;
}
