import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Input, Popover, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useMemo } from "react";
import LocationType from "../../components/Location/LocationType.component";
import {
  extractPsychotherapyExplanation,
  getCptCodeLabelByPsychotherapyMinutes,
  showAddonCptCode,
  totalTimeSpentOnPsychotherapy,
} from "../../domain/notes.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  generateSessionInsight,
  getNotesDetails,
  handleGenerateTreatmentPlan,
  updateNote,
} from "../../service/notes.service";
import {
  setEapIntakeNotes,
  setEmdrNotes,
  setIntakeNotes,
  setNotesDetails,
  setPsychFollowupNote,
  setPsychIntakeNotes,
  setSolPsychFollowupNotes,
  setSolPsychIntakeNotes,
  setSolTherapyFollowupNotes,
  setSolTherapyIntakeNotes,
} from "../../slices/notesSlice";
import {
  EventType,
  NoteSection,
  determineNoteType,
  trackEvent,
} from "../../utils/analytics";
import {
  copyAddonDetails,
  copySectionContent,
  copySessionDetails,
  durationTextFromType,
} from "../../utils/datamanipulation.utils";
import styles from "./notes.module.scss";
import { setShowDemoSignupModal } from "../../slices/appStateSlice";

const NotesNiew = () => {
  const componentName = "notes";
  const maxTimeout = 2 * 60 * 1000;

  const dispatch = useAppDispatch();

  const { intakeNotes, eapIntakeNotes, psychIntakeNotes, emdrNotes, psychFollowupNote } =
    useAppSelector((state) => state.notes);

  const [editTreatmentPlan, setEditTreatmentPlan] = React.useState(false);
  const [isOldInsights, setIsOldInsights] = React.useState(false);
  const [isOldTreatmentPlan, setIsOldTreatmentPlan] = React.useState(false);
  const [sessionInsightsContent, setSessionInsightsContent] =
    React.useState("");
  const [sessionTreatmentPlanContent, setSessionTreatmentPlanContent] =
    React.useState("");
  const [expandedSessionInsights, setExpandedSessionInsights] =
    React.useState(false);
  const [expandedSessionTreatmentPlan, setExpandedSessionTreatmentPlan] =
    React.useState(false);

  const { isDemoPath } = useAppSelector(
    (state) => state.appState
  );

  const { notesDetails } = useAppSelector((state) => state.notes);

  const [editedPsychotherapyMinutes, setEditedPsychotherapyMinutes] =
    React.useState(() => notesDetails?.psychotherapy_minutes || 0);

  const checkDemoPath = () => {
    if (isDemoPath) {
      dispatch(setShowDemoSignupModal(true));
    }
    return isDemoPath;
  }

  const handleSaveClick = async () => {
    if (checkDemoPath()) {
      return;
    }
    if (notesDetails) {
      trackEvent(EventType.SAVE_NOTE, {
        recordingId: notesDetails?.id,
        noteType: determineNoteType(notesDetails.note_type),
      });

      const updateNoteData = {
        audio_id: notesDetails?.id,
        data: notesDetails.data,
        subject: notesDetails.subject,
        objective: notesDetails.objective,
        assessment: notesDetails.assessment,
        plan: notesDetails.plan,
        intake_note: intakeNotes,
        eap_intake_note: eapIntakeNotes,
        psych_intake_note: psychIntakeNotes,
        psych_followup_note: notesDetails.psych_followup_note,
        emdr_note: emdrNotes,
        sol_psych_intake_note: notesDetails.sol_psych_intake_note,
        sol_psych_followup_note: notesDetails.sol_psych_followup_note,
        sol_therapy_intake_note: notesDetails.sol_therapy_intake_note,
        sol_therapy_followup_note: notesDetails.sol_therapy_followup_note,
      };

      await updateNote(updateNoteData)
        .then((response) => {
          const { data } = response;
          dispatch(setNotesDetails(data));
          dispatch(setIntakeNotes(data.intake_note));
          dispatch(setEapIntakeNotes(data.eap_intake_note));
          dispatch(setPsychIntakeNotes(data.psych_intake_note));
          if (data.psych_followup_note) {
            dispatch(setPsychFollowupNote(data.psych_followup_note));
          }
          dispatch(setEmdrNotes(data.emdr_note));
          if (data.sol_psych_intake_note) {
            dispatch(setSolPsychIntakeNotes(data.sol_psych_intake_note));
          }
          if (data.sol_psych_followup_note) {
            dispatch(setSolPsychFollowupNotes(data.sol_psych_followup_note));
          }
          if (data.sol_therapy_intake_note) {
            dispatch(setSolTherapyIntakeNotes(data.sol_therapy_intake_note));
          }
          if (data.sol_therapy_followup_note) {
            dispatch(
              setSolTherapyFollowupNotes(data.sol_therapy_followup_note)
            );
          }
          message.success("Note saved successfully");
        })
        .catch(() => {
          message.error("Error saving note");
        });
    }
  };

  const handleSaveAddon = async () => {
    const updatedData = {
      audio_id: notesDetails?.id,
      psychotherapy_minutes: editedPsychotherapyMinutes,
      psychotherapy_minutes_explanation:
        notesDetails?.psychotherapy_minutes_explanation,
    };

    await updateNote(updatedData)
      .then((response) => {
        const { data } = response;
        dispatch(setNotesDetails(data));
        message.success("Add-on information updated successfully");
      })
      .catch(() => {
        message.error("Error updating add-on information");
      });
  };

  const handleGenerateNewInsights = async () => {
    if (checkDemoPath()) {
      return;
    }
    trackEvent(EventType.GENERATE_SESSION_INSIGHTS, {
      recordingId: notesDetails?.id,
    });

    setIsOldInsights(false);
    generateSessionInsight(notesDetails?.id)
      .then((result) => {
        setSessionInsightsContent(result.data["session_insights"]);
      })
      .catch((error) => {
        console.error(error);
        setIsOldInsights(true);
        message.error("Error generating session insights. Please try again.");
      })
      .finally(() => {});
  };

  const handleGenerateNewTreatmentPlan = async () => {
    if (checkDemoPath()) {
      return;
    }
    trackEvent(EventType.GENERATE_SESSION_PLAN, {
      recordingId: notesDetails?.id,
    });

    setIsOldTreatmentPlan(false);
    handleGenerateTreatmentPlan(notesDetails?.id)
      .then((result) => {
        setSessionTreatmentPlanContent(result.data["treatment_plan"]);
      })
      .catch((error) => {
        console.error(error);
        setIsOldTreatmentPlan(true);
        message.error("Error generating treatment plan. Please try again.");
      })
      .finally(() => {});
  };

  const pollSessionInsights = async () => {
    try {
      const response = await getNotesDetails(notesDetails?.id);
      const data = await response.data;
      if (data.session_insights) {
        setSessionInsightsContent(data.session_insights);
        return;
      }

      // If not done processing insights then do the poll.
      const currentDate = new Date();
      const genNoteEndDate = new Date(data.stats_generate_note_end_at);
      if (currentDate.getTime() - genNoteEndDate.getTime() <= maxTimeout) {
        setTimeout(() => pollSessionInsights(), 3000);
      } else {
        setIsOldInsights(true);
      }
    } catch (error) {
      console.error("Error checking audio status:", error);
    }
  };

  const pollSessionTreatmentPlan = async () => {
    try {
      const response = await getNotesDetails(notesDetails?.id);
      const data = response.data;

      if (data.treatment_plan) {
        setSessionTreatmentPlanContent(data.treatment_plan);
        return;
      }

      // If not done processing insights then do the poll.
      const currentDate = new Date();
      const genNoteEndDate = new Date(data.stats_generate_note_end_at);
      if (currentDate.getTime() - genNoteEndDate.getTime() <= maxTimeout) {
        setTimeout(() => pollSessionTreatmentPlan(), 3000);
      } else {
        setIsOldTreatmentPlan(true);
      }
    } catch (error) {
      console.error("Error checking audio status:", error);
    }
  };

  const psychoTherapyMinutes = useMemo(() => {
    if (notesDetails?.psychotherapy_minutes) {
      return notesDetails.psychotherapy_minutes;
    }
    if (notesDetails?.sol_psych_intake_note) {
      return totalTimeSpentOnPsychotherapy(notesDetails.sol_psych_intake_note);
    }
    if (notesDetails?.sol_psych_followup_note) {
      return totalTimeSpentOnPsychotherapy(
        notesDetails?.sol_psych_followup_note
      );
    }

    return 0;
  }, [notesDetails]);

  const psychoTherapyExplanation = useMemo(() => {
    if (notesDetails?.psychotherapy_minutes_explanation) {
      return notesDetails.psychotherapy_minutes_explanation;
    }

    if (notesDetails?.sol_psych_intake_note) {
      return extractPsychotherapyExplanation(
        notesDetails.sol_psych_intake_note
      );
    }

    if (notesDetails?.sol_psych_followup_note) {
      return extractPsychotherapyExplanation(
        notesDetails.sol_psych_followup_note
      );
    }

    return "";
  }, [notesDetails]);

  const handlePsychoTherapyMinutesUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newMinutes =
      event.target.value === "" ? 0 : Number(event.target.value);
    setEditedPsychotherapyMinutes(newMinutes);
  };

  // biome-ignore lint: no need to add pollSessionTreatmentPlan to the dependency array
  React.useEffect(() => {
    const currentDate = new Date();
    const genNoteEndDate = notesDetails
      ? new Date(notesDetails.stats_generate_note_end_at)
      : null;
    const isWithinTimeout =
      genNoteEndDate &&
      currentDate.getTime() - genNoteEndDate.getTime() <= maxTimeout;

    if (notesDetails?.id) {
      if (!notesDetails.session_insights) {
        pollSessionInsights();
      } else {
        setSessionInsightsContent(notesDetails.session_insights);
      }

      if (
        notesDetails.note_type === 1 ||
        notesDetails.note_type === 6 ||
        notesDetails.note_type === 8
      ) {
        if (!notesDetails.treatment_plan) {
          pollSessionTreatmentPlan();
        } else {
          setSessionTreatmentPlanContent(notesDetails.treatment_plan);
        }
      } else if (isWithinTimeout) {
        setTimeout(() => pollSessionTreatmentPlan(), 3000);
      } else {
        setIsOldTreatmentPlan(true);
      }
    } else if (isWithinTimeout) {
      setTimeout(() => pollSessionTreatmentPlan(), 3000);
    } else {
      setIsOldInsights(true);
      setIsOldTreatmentPlan(true);
    }
  }, [notesDetails]);

  const updateTreatmentPlan =() => {
    updateNote({
      audio_id: notesDetails?.id,
      treatment_plan: sessionTreatmentPlanContent,
    })
      .then((result) => {
        message.success("Treatment plan saved successfully");
        setEditTreatmentPlan(false);
        setSessionTreatmentPlanContent(result.data["treatment_plan"]);
      })
      .catch((error) => {
        message.error("Error saving treatment plan");
        console.log(error);
      });
  };

  if (!notesDetails) {
    return <></>;
  }

  return (
    <>
      <Flex gap={10} vertical>
        <Flex
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
          gap={10}
        >
          <Card
            id="special-card"
            hoverable
            title={
              <Flex align="center" gap={10}>
                Nudge Session Insights{" "}
              </Flex>
            }
            extra={
              <Popover
                placement="left"
                title={"Nudge Session Insights"}
                content={
                  <>
                    Nudge Insights is a powerful tool designed to assist
                    <br />
                    the clinician in gaining valuable insights post-session.
                    <br />
                    The tool is not meant to substitue for a clinician's
                    <br />
                    insights, but rather to assist the clinician with ideas.
                    <br />
                    <br />
                    Nudge serves as an assistant to the clinician,
                    <br />
                    strengthening therapeutic alliance by aiding the
                    <br />
                    clinician in improving outcomes.
                    <br />
                  </>
                }
              >
                <span
                  className={`${styles[`${componentName}__main-section-span`]}`}
                  style={{ color: "grey" }}
                >
                  (what is this?)
                </span>
              </Popover>
            }
            style={{ width: "100%" }}
          >
            {isOldInsights ? (
              <>
                <Flex vertical gap={10}>
                  <Typography>
                    This might be an old note. Would you like your Nudge
                    assistant to generate session insights?
                  </Typography>
                  <Flex>
                    <Button type="primary" onClick={handleGenerateNewInsights}>
                      Generate
                    </Button>
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                {!sessionInsightsContent ? (
                  <Flex align="center">
                    <LoadingOutlined />
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontWeight: "bold",
                        color: "#606060",
                      }}
                    >
                      Your Nudge assistant is generating session insights
                    </Typography>
                  </Flex>
                ) : (
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    <Flex vertical gap={10}>
                      <Flex>
                        {expandedSessionInsights
                          ? sessionInsightsContent
                          : `${sessionInsightsContent.split(" ").slice(0, 35).join(" ")}${
                              sessionInsightsContent.split(" ").length > 35
                                ? "..."
                                : ""
                            }`}
                      </Flex>
                      <Flex>
                        <Button
                          type={expandedSessionInsights ? "default" : "primary"}
                          onClick={() => {
                            if (!expandedSessionInsights) {
                              trackEvent(EventType.EXPAND_SESSION_INSIGHTS, {
                                recordingId: notesDetails?.id,
                              });
                            }
                            setExpandedSessionInsights(
                              !expandedSessionInsights,
                            );
                          }}
                        >
                          {expandedSessionInsights ? "Show Less" : "Show More"}
                        </Button>{" "}
                        &nbsp;
                        {expandedSessionInsights && (
                          <Button
                            type="default"
                            className={`${styles[`${componentName}__main-section-copy-button`]}`}
                            onClick={() =>
                              copySectionContent(
                                notesDetails,
                                sessionInsightsContent,
                                NoteSection.NUDGE_INSIGHTS,
                              )
                            }
                            icon={<CopyOutlined />}
                          >
                            Copy
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </Typography>
                )}
              </>
            )}
          </Card>
        </Flex>
      </Flex>

      {(notesDetails.note_type === 1 ||
        notesDetails.note_type === 6 ||
        notesDetails.note_type === 8) && (
        <Flex gap={10} vertical>
          <Flex
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            gap={10}
          >
            <Card
              id="special-card"
              hoverable
              title={
                <Flex align="center" gap={10}>
                  Nudge Treatment Plan{" "}
                </Flex>
              }
              extra={
                <Popover
                  placement="left"
                  title={"Nudge Treatment Plan"}
                  content={
                    <>
                      Nudge Treatment Plan is a powerful tool designed to assist
                      <br />
                      therapists in creating a treatment plan post intake
                      session.
                      <br />
                      <br />
                      Our treatment plans are still in beta and will improve
                      over time.
                      <br />
                      We urge you to treat our treatment plan as a
                      recommendation and
                      <br />
                      use your best judgement as you know your client the best.
                      <br />
                    </>
                  }
                >
                  <span
                    className={`${styles[`${componentName}__main-section-span`]}`}
                    style={{ color: "grey" }}
                  >
                    (what is this?)
                  </span>
                </Popover>
              }
              style={{ width: "100%" }}
            >
              {isOldTreatmentPlan ? (
                <>
                  <Flex vertical gap={10}>
                    <Typography>
                      This might be an old note. Would you like your Nudge
                      assistant to generate a treatment plan?
                    </Typography>
                    <Flex>
                      <Button
                        type="primary"
                        onClick={handleGenerateNewTreatmentPlan}
                      >
                        Generate
                      </Button>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  {!sessionTreatmentPlanContent ? (
                    <Flex align="center">
                      <LoadingOutlined />
                      <Typography
                        style={{
                          marginLeft: "10px",
                          fontWeight: "bold",
                          color: "#606060",
                        }}
                      >
                        Your Nudge assistant is generating a treatment plan
                      </Typography>
                    </Flex>
                  ) : (
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      <Flex vertical gap={10}>
                        {!editTreatmentPlan ? (
                          <Flex>
                            {expandedSessionTreatmentPlan
                              ? sessionTreatmentPlanContent
                              : `${sessionTreatmentPlanContent.split(" ").slice(0, 35).join(" ")}${
                                  sessionTreatmentPlanContent.split(" ")
                                    .length > 35
                                    ? "..."
                                    : ""
                                }`}
                          </Flex>
                        ) : (
                          <TextArea
                            className={`${styles[`${componentName}__main-section-textarea}`]}`}
                            value={sessionTreatmentPlanContent}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            onChange={(event) => {
                              setSessionTreatmentPlanContent(
                                event.target.value,
                              );
                            }}
                          />
                        )}
                        <Flex gap={10}>
                          {!editTreatmentPlan && (
                            <Button
                              type={
                                expandedSessionTreatmentPlan
                                  ? "default"
                                  : "primary"
                              }
                              onClick={() => {
                                if (!expandedSessionTreatmentPlan) {
                                  trackEvent(EventType.EXPAND_SESSION_PLAN, {
                                    recordingId: notesDetails?.id,
                                  });
                                }
                                setExpandedSessionTreatmentPlan(
                                  !expandedSessionTreatmentPlan,
                                );
                              }}
                            >
                              {expandedSessionTreatmentPlan
                                ? "Show Less"
                                : "Show More"}
                            </Button>
                          )}
                          {expandedSessionTreatmentPlan && (
                            <Button
                              type="default"
                              className={`${styles[`${componentName}__main-section-copy-button`]}`}
                              onClick={() =>
                                copySectionContent(
                                  notesDetails,
                                  sessionTreatmentPlanContent,
                                  NoteSection.TREATMENT_PLAN,
                                )
                              }
                              icon={<CopyOutlined />}
                            >
                              Copy
                            </Button>
                          )}
                          {editTreatmentPlan && !isDemoPath && (
                            <Button
                              className={`${styles[`${componentName}__main-section-save-button`]}`}
                              onClick={updateTreatmentPlan}
                              type="primary"
                            >
                              Save Changes
                            </Button>
                          )}
                          {!editTreatmentPlan && !isDemoPath && (
                            <Button
                              onClick={() => {
                                setEditTreatmentPlan(!editTreatmentPlan);
                              }}
                              type="primary"
                            >
                              Edit
                            </Button>
                          )}
                          {editTreatmentPlan && (
                            <Button
                              onClick={() => {
                                setEditTreatmentPlan(!editTreatmentPlan);
                                setSessionTreatmentPlanContent(
                                  notesDetails?.treatment_plan as string
                                );
                              }}
                              type="primary"
                            >
                              {"Discard"}
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </Typography>
                  )}
                </>
              )}
            </Card>
          </Flex>
        </Flex>
      )}

      {(notesDetails?.location_type != null ||
        notesDetails?.duration_type != null) && (
        <Flex
          gap={10}
          vertical
          style={{
            marginBottom: "20px",
          }}
        >
          <Flex vertical gap={20}>
            <Flex>
              <Card
                className={`${styles[`${componentName}__main-section`]}`}
                hoverable
              >
                <Flex justify="space-between" align="center">
                  <span
                    className={`${styles[`${componentName}__main-section-title`]}`}
                  >
                    Session Details:
                  </span>{" "}
                  <Button
                    type="default"
                    className={`${
                      styles[`${componentName}__main-section-copy-button`]
                    }`}
                    onClick={() => copySessionDetails(notesDetails)}
                    icon={<CopyOutlined />}
                  >
                    Copy
                  </Button>
                </Flex>
                <div
                  className={`${styles[`${componentName}__main-section-description`]}`}
                >
                  {notesDetails.location_type !== undefined &&
                    notesDetails.location_type !== null && (
                      <Flex
                        className={`${styles[`${componentName}__main-section-description-col`]}`}
                      >
                        <span>
                          <strong>Location: </strong>
                        </span>
                        &nbsp;
                        <LocationType
                          isStyled={false}
                          location_type={notesDetails.location_type}
                        />
                      </Flex>
                    )}
                  {notesDetails.duration_type !== undefined &&
                  notesDetails.duration_type !== null ? (
                    <Flex
                      align="flex-start"
                      className={`${styles[`${componentName}__main-section-description-col`]}`}
                    >
                      <span
                        className={`${
                          styles[
                            `${componentName}__main-section-description-col-label`
                          ]
                        }`}
                      >
                        <strong>CPT Code: </strong>
                      </span>{" "}
                      <span>
                        {durationTextFromType(
                          notesDetails.duration_type as number
                        )}
                      </span>
                    </Flex>
                  ) : null}
                </div>
              </Card>
            </Flex>
          </Flex>
        </Flex>
      )}

      {notesDetails.note_type === 0 && (
        <Flex vertical gap={20}>
          <Flex>
            <Card
              className={`${styles[`${componentName}__main-section`]}`}
              hoverable
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Subjective
                </span>{" "}
                <Button
                  type="default"
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.subject,
                      NoteSection.SUBJECTIVE
                    )
                  }
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.subject}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        subject: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
          <Flex>
            <Card
              className={`${styles[`${componentName}__main-section`]}`}
              hoverable
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Objective
                </span>{" "}
                <Button
                  type="default"
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.objective,
                      NoteSection.OBJECTIVE
                    )
                  }
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.objective}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        objective: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
          <Flex>
            <Card
              className={`${styles[`${componentName}__main-section`]}`}
              hoverable
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Assessment
                </span>{" "}
                <Button
                  type="default"
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.assessment,
                      NoteSection.ASSESSMENT
                    )
                  }
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.assessment}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        assessment: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
          <Flex>
            <Card
              className={`${styles[`${componentName}__main-section`]}`}
              hoverable
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Plan
                </span>{" "}
                <Button
                  type="default"
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.plan,
                      NoteSection.PLAN
                    )
                  }
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.plan}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        plan: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
        </Flex>
      )}
      {notesDetails.note_type === 1 && (
        <>
          <Flex>
            <Card style={{ width: "100%" }} hoverable>
              {notesDetails && notesDetails.note_type === 1 && (
                <Flex vertical>
                  <Flex justify="space-between" align="center">
                    <span
                      className={`${
                        styles[`${componentName}__main-section-title`]
                      }`}
                    >
                      Therapy Intake
                    </span>{" "}
                    <Button
                      type="default"
                      onClick={() =>
                        copySectionContent(
                          notesDetails,
                          intakeNotes,
                          NoteSection.INTAKE_NOTES
                        )
                      }
                      className={`${
                        styles[`${componentName}__main-section-copy-button`]
                      }`}
                      icon={<CopyOutlined />}
                    >
                      Copy
                    </Button>
                  </Flex>
                  <div
                    className={`${
                      styles[`${componentName}__main-section-description`]
                    }`}
                  >
                    <TextArea
                      className={`${
                        styles[`${componentName}__main-section-textarea`]
                      }`}
                      value={intakeNotes}
                      autoSize={{ minRows: 3, maxRows: 25 }}
                      onChange={(event) => {
                        dispatch(setIntakeNotes(event.target.value));
                      }}
                    />
                  </div>
                  <div
                    className={`${
                      styles[`${componentName}__main-section-action`]
                    }`}
                  >
                    {!isDemoPath && (
                      <Button
                        type="primary"
                        onClick={handleSaveClick}
                        className={`${
                          styles[`${componentName}__main-section-save-button`]
                        }`}
                      >
                        Save Changes
                      </Button>
                    )}
                  </div>
                </Flex>
              )}
            </Card>
          </Flex>
        </>
      )}
      {notesDetails.note_type === 4 && (
        <>
          <Flex>
            <Card style={{ width: "100%" }} hoverable>
              {notesDetails && notesDetails.note_type === 4 && (
                <Flex vertical>
                  <Flex justify="space-between" align="center">
                    <span
                      className={`${
                        styles[`${componentName}__main-section-title`]
                      }`}
                    >
                      EAP Intake
                    </span>{" "}
                    <Button
                      type="default"
                      onClick={() =>
                        copySectionContent(
                          notesDetails,
                          eapIntakeNotes,
                          NoteSection.EAP_INTAKE_NOTES
                        )
                      }
                      className={`${
                        styles[`${componentName}__main-section-copy-button`]
                      }`}
                      icon={<CopyOutlined />}
                    >
                      Copy
                    </Button>
                  </Flex>
                  <div
                    className={`${
                      styles[`${componentName}__main-section-description`]
                    }`}
                  >
                    <TextArea
                      className={`${
                        styles[`${componentName}__main-section-textarea`]
                      }`}
                      value={eapIntakeNotes}
                      autoSize={{ minRows: 3, maxRows: 25 }}
                      onChange={(event) => {
                        dispatch(setEapIntakeNotes(event.target.value));
                      }}
                    />
                  </div>
                  <div
                    className={`${
                      styles[`${componentName}__main-section-action`]
                    }`}
                  >
                    {!isDemoPath && (
                      <Button
                        type="primary"
                        onClick={handleSaveClick}
                        className={`${
                          styles[`${componentName}__main-section-save-button`]
                        }`}
                      >
                        Save Changes
                      </Button>
                    )}
                  </div>
                </Flex>
              )}
            </Card>
          </Flex>
        </>
      )}
      {notesDetails.note_type === 5 && (
        <>
          <Flex>
            <Card
              className={`${styles[`${componentName}-full-width`]}`}
              hoverable
            >
              {notesDetails && notesDetails.note_type === 5 && (
                <Flex vertical>
                  <Flex justify="space-between" align="center">
                    <span
                      className={`${
                        styles[`${componentName}__main-section-title`]
                      }`}
                    >
                      Psychatric Intake
                    </span>{" "}
                    <Button
                      type="default"
                      onClick={() =>
                        copySectionContent(
                          notesDetails,
                          psychIntakeNotes,
                          NoteSection.PSYCH_INTAKE_NOTES
                        )
                      }
                      className={`${
                        styles[`${componentName}__main-section-copy-button`]
                      }`}
                      icon={<CopyOutlined />}
                    >
                      Copy
                    </Button>
                  </Flex>
                  <div
                    className={`${
                      styles[`${componentName}__main-section-description`]
                    }`}
                  >
                    <TextArea
                      className={`${
                        styles[`${componentName}__main-section-textarea`]
                      }`}
                      value={psychIntakeNotes}
                      autoSize={{ minRows: 3, maxRows: 25 }}
                      onChange={(event) => {
                        dispatch(setPsychIntakeNotes(event.target.value));
                      }}
                    />
                  </div>
                  <div
                    className={`${
                      styles[`${componentName}__main-section-action`]
                    }`}
                  >
                    {!isDemoPath && (
                      <Button
                        type="primary"
                        onClick={handleSaveClick}
                        className={`${
                          styles[`${componentName}__main-section-save-button`]
                        }`}
                      >
                        Save Changes
                      </Button>
                    )}
                  </div>
                </Flex>
              )}
            </Card>
          </Flex>
        </>
      )}
      {notesDetails.note_type === 2 && (
        <Flex vertical gap={20}>
          <Flex>
            <Card
              hoverable
              className={`${styles[`${componentName}__main-section`]}`}
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Data
                </span>{" "}
                <Button
                  type="default"
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.data,
                      NoteSection.DATA
                    )
                  }
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.data}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        data: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
          <Flex>
            <Card
              hoverable
              className={`${styles[`${componentName}__main-section`]}`}
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Assessment
                </span>{" "}
                <Button
                  type="default"
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.assessment,
                      NoteSection.ASSESSMENT
                    )
                  }
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.assessment}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        assessment: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
          <Flex>
            <Card
              hoverable
              className={`${styles[`${componentName}__main-section`]}`}
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${styles[`${componentName}__main-section-title`]}`}
                >
                  Plan
                </span>{" "}
                <Button
                  type="default"
                  className={`${styles[`${componentName}__main-section-copy-button`]}`}
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      notesDetails.plan,
                      NoteSection.PLAN
                    )
                  }
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${styles[`${componentName}__main-section-description`]}`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={notesDetails.plan}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={(event) => {
                    dispatch(
                      setNotesDetails({
                        ...notesDetails,
                        plan: event.target.value,
                      }),
                    );
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${styles[`${componentName}__main-section-save-button`]}`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
        </Flex>
      )}
      {notesDetails && notesDetails.note_type === 3 && (
        <Flex vertical>
          <Flex>
            <Card
              className={`${styles[`${componentName}__main-section`]}`}
              hoverable
            >
              <Flex justify="space-between" align="center">
                <span
                  className={`${
                    styles[`${componentName}__main-section-title`]
                  }`}
                >
                  EMDR
                </span>{" "}
                <Button
                  type="default"
                  onClick={() =>
                    copySectionContent(
                      notesDetails,
                      emdrNotes,
                      NoteSection.EMDR_NOTES
                    )
                  }
                  className={`${
                    styles[`${componentName}__main-section-copy-button`]
                  }`}
                  icon={<CopyOutlined />}
                >
                  Copy
                </Button>
              </Flex>
              <div
                className={`${
                  styles[`${componentName}__main-section-description`]
                }`}
              >
                <TextArea
                  className={`${
                    styles[`${componentName}__main-section-textarea`]
                  }`}
                  value={emdrNotes}
                  autoSize={{ minRows: 3, maxRows: 25 }}
                  onChange={(event) => {
                    dispatch(setEmdrNotes(event.target.value));
                  }}
                />
              </div>
              <div
                className={`${styles[`${componentName}__main-section-action`]}`}
              >
                {!isDemoPath && (
                  <Button
                    type="primary"
                    onClick={handleSaveClick}
                    className={`${
                      styles[`${componentName}__main-section-save-button`]
                    }`}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </Card>
          </Flex>
        </Flex>
      )}
      {notesDetails.note_type === 6 && (
        <Flex>
          <Card
            className={`${styles[`${componentName}__main-section`]}`}
            hoverable
          >
            <Flex justify="space-between" align="center">
              <span
                className={`${styles[`${componentName}__main-section-title`]}`}
              >
                Psychiatric Intake
              </span>
            </Flex>
            <div
              className={`${styles[`${componentName}__main-section-description`]}`}
            >
              <TextArea
                className={`${
                  styles[`${componentName}__main-section-textarea`]
                }`}
                value={notesDetails.sol_psych_intake_note}
                autoSize={{ minRows: 3, maxRows: 25 }}
                onChange={(event) => {
                  dispatch(
                    setNotesDetails({
                      ...notesDetails,
                      sol_psych_intake_note: event.target.value,
                    }),
                  );
                }}
              />
            </div>
            <div
              className={`${styles[`${componentName}__main-section-action`]}`}
            >
              {!isDemoPath && (
                <Button
                  type="primary"
                  onClick={handleSaveClick}
                  className={`${styles[`${componentName}__main-section-save-button`]}`}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Card>
        </Flex>
      )}
      {notesDetails.note_type === 7 && (
        <Flex>
          <Card
            className={`${styles[`${componentName}__main-section`]}`}
            hoverable
          >
            <Flex justify="space-between" align="center">
              <span
                className={`${styles[`${componentName}__main-section-title`]}`}
              >
                Psychiatric Follow-up
              </span>
            </Flex>
            <div
              className={`${styles[`${componentName}__main-section-description`]}`}
            >
              <TextArea
                className={`${
                  styles[`${componentName}__main-section-textarea`]
                }`}
                value={notesDetails.sol_psych_followup_note}
                autoSize={{ minRows: 3, maxRows: 25 }}
                onChange={(event) => {
                  dispatch(
                    setNotesDetails({
                      ...notesDetails,
                      sol_psych_followup_note: event.target.value,
                    }),
                  );
                }}
              />
            </div>
            <div
              className={`${styles[`${componentName}__main-section-action`]}`}
            >
              {!isDemoPath && (
                <Button
                  type="primary"
                  onClick={handleSaveClick}
                  className={`${styles[`${componentName}__main-section-save-button`]}`}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Card>
        </Flex>
      )}
      {notesDetails.note_type === 8 && (
        <Flex>
          <Card
            className={`${styles[`${componentName}__main-section`]}`}
            hoverable
          >
            <Flex justify="space-between" align="center">
              <span
                className={`${styles[`${componentName}__main-section-title`]}`}
              >
                Therapy Intake
              </span>
            </Flex>
            <div
              className={`${styles[`${componentName}__main-section-description`]}`}
            >
              <TextArea
                className={`${
                  styles[`${componentName}__main-section-textarea`]
                }`}
                value={notesDetails.sol_therapy_intake_note}
                autoSize={{ minRows: 3, maxRows: 25 }}
                onChange={(event) => {
                  dispatch(
                    setNotesDetails({
                      ...notesDetails,
                      sol_therapy_intake_note: event.target.value,
                    }),
                  );
                }}
              />
            </div>
            <div
              className={`${styles[`${componentName}__main-section-action`]}`}
            >
              {!isDemoPath && (
                <Button
                  type="primary"
                  onClick={handleSaveClick}
                  className={`${styles[`${componentName}__main-section-save-button`]}`}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Card>
        </Flex>
      )}
      {notesDetails.note_type === 9 || notesDetails.note_type === 10 ? (
        <Flex>
          <Card
            className={`${styles[`${componentName}__main-section`]}`}
            hoverable
          >
            <Flex justify="space-between" align="center">
              <span
                className={`${styles[`${componentName}__main-section-title`]}`}
              >
                Therapy Follow-up
              </span>
            </Flex>
            <div
              className={`${styles[`${componentName}__main-section-description`]}`}
            >
              <TextArea
                className={`${
                  styles[`${componentName}__main-section-textarea`]
                }`}
                value={notesDetails.sol_therapy_followup_note}
                autoSize={{ minRows: 3, maxRows: 25 }}
                onChange={(event) => {
                  dispatch(
                    setNotesDetails({
                      ...notesDetails,
                      sol_therapy_followup_note: event.target.value,
                    }),
                  );
                }}
              />
            </div>
            <div
              className={`${styles[`${componentName}__main-section-action`]}`}
            >
              {!isDemoPath && (
                <Button
                  type="primary"
                  onClick={handleSaveClick}
                  className={`${styles[`${componentName}__main-section-save-button`]}`}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Card>
        </Flex>
      ) : null}

      {notesDetails.note_type === 11 ? (
        <Flex>
          <Card
            className={`${styles[`${componentName}__main-section`]}`}
            hoverable
          >
            <Flex justify="space-between" align="center">
              <span
                className={`${styles[`${componentName}__main-section-title`]}`}
              >
                Psychiatric Follow-up
              </span>
            </Flex>
            <div
              className={`${
                styles[`${componentName}__main-section-description`]
              }`}
            >
              <TextArea
                className={`${
                  styles[`${componentName}__main-section-textarea`]
                }`}
                value={notesDetails.psych_followup_note}
                autoSize={{ minRows: 3, maxRows: 25 }}
                onChange={(event) => {
                  dispatch(
                    setNotesDetails({
                      ...notesDetails,
                      psych_followup_note: event.target.value,
                    })
                  );
                }}
              />
            </div>
            <div
              className={`${styles[`${componentName}__main-section-action`]}`}
            >
              {!isDemoPath && (
                <Button
                  type="primary"
                  onClick={handleSaveClick}
                  className={`${
                    styles[`${componentName}__main-section-save-button`]
                  }`}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Card>
        </Flex>
      ) : null}

      {showAddonCptCode(notesDetails, psychoTherapyMinutes) ? (
        <Flex
          gap={10}
          vertical
          style={{
            marginTop: "20px",
          }}
        >
          <Flex vertical gap={20}>
            <Flex>
              <Card
                className={`${styles[`${componentName}__main-section`]}`}
                hoverable
              >
                <Flex justify="space-between" align="center">
                  <span
                    className={`${
                      styles[`${componentName}__main-section-title`]
                    }`}
                  >
                    Psychotherapy Add-on:
                  </span>{" "}
                  <Button
                    type="default"
                    className={`${
                      styles[`${componentName}__main-section-copy-button`]
                    }`}
                    onClick={() => copyAddonDetails(notesDetails)}
                    icon={<CopyOutlined />}
                  >
                    Copy
                  </Button>
                </Flex>
                <div
                  className={`${
                    styles[`${componentName}__main-section-description`]
                  }`}
                >
                  <div
                    style={{
                      fontSize: 10,
                      marginBottom: 10,
                      padding: 8,
                      backgroundColor: "#fffedb",
                      borderRadius: 8
                    }}
                  >
                    The Add-on CPT code below is the code you chose when you
                    created the note. Nudge estimates psychotherapy time from
                    the actual session and provides an explanation. The
                    estimated psychotherapy time from Nudge may differ from the time
                    time interval of your Add-on CPT code.
                  </div>
                  <Flex
                    align="flex-start"
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span
                      className={`${
                        styles[
                          `${componentName}__main-section-description-col-label`
                        ]
                      }`}
                    >
                      <strong>CPT Code: </strong>
                    </span>{" "}
                    <span>
                      {getCptCodeLabelByPsychotherapyMinutes(
                        psychoTherapyMinutes,
                        notesDetails
                      )}
                    </span>
                  </Flex>
                  <Flex
                    align="center"
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span
                      className={`${
                        styles[
                          `${componentName}__main-section-description-col-label`
                        ]
                      }`}
                    >
                      <strong>Psychotherapy Minutes: </strong>
                    </span>{" "}
                    <Input
                      type="number"
                      defaultValue={psychoTherapyMinutes}
                      value={editedPsychotherapyMinutes}
                      onChange={handlePsychoTherapyMinutesUpdate}
                      style={{ width: "80px", marginLeft: "8px" }}
                    />
                  </Flex>
                  <Flex
                    align="flex-start"
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span
                      className={`${
                        styles[
                          `${componentName}__main-section-description-col-label`
                        ]
                      }`}
                    >
                      <strong>Explanation:</strong>
                    </span>{" "}
                  </Flex>
                  <TextArea
                    className={`${
                      styles[`${componentName}__main-section-textarea`]
                    }`}
                    value={psychoTherapyExplanation}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    onChange={(event) => {
                      dispatch(
                        setNotesDetails({
                          ...notesDetails,
                          psychotherapy_minutes_explanation: event.target.value,
                        })
                      );
                    }}
                  />
                </div>
                <div
                  className={`${
                    styles[`${componentName}__main-section-action`]
                  }`}
                >
                  <Button
                    type="primary"
                    onClick={handleSaveAddon}
                    className={`${
                      styles[`${componentName}__main-section-save-button`]
                    }`}
                  >
                    Save Changes
                  </Button>
                </div>
              </Card>
            </Flex>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default NotesNiew;
