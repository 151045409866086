import styles from "./docs.module.scss";

export default function Terms() {
  const componentName = "docs";
  return (
    <>
      <div className={`${styles[`${componentName}__container`]}`}>
        <div className={`${styles[`${componentName}__container-header`]}`}>
          Terms of Service
        </div>
        <div className={`${styles[`${componentName}__container-desc`]}`}>
          <span>
            {" "}
            <b>Terms of Service for Nudge </b>
          </span>
          <br />
          <br />
          Welcome to Nudge, a service that provides an AI-powered platform to
          assist mental health professionals in transcribing and generating
          notes for their therapy sessions. By using our website, application,
          and related services (collectively, the "Services"), you agree to be
          bound by these Terms of Service (the "Terms"). <br />
          <br />
          <ol>
            <li>
              <b>Acceptance of Terms:</b> By accessing or using our Services,
              you acknowledge that you have read, understood, and agree to be
              bound by these Terms, as well as our Privacy Policy. If you do not
              agree with any part of these Terms, please refrain from using our
              Services.
            </li>
            <li>
              <b>User Accounts:</b> To access our Services, you must create a
              user account. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account. You agree to provide accurate and
              complete information when creating your account and to update your
              information as necessary.
            </li>
            <li>
              <b>Use of Services:</b> Our Services are intended for use by
              licensed mental health professionals. By using our Services, you
              represent and warrant that you are appropriately licensed to
              provide mental health services in accordance with applicable laws
              and regulations.
            </li>
            <br />
            <br />
            <div className={`${styles[`${componentName}__container-mid`]}`}>
              You agree to use our Services only for lawful purposes and in
              compliance with these Terms. You shall not use our Services to
              pursue any illegal activities or to infringe upon the intellectual
              property rights of others.
            </div>
            <br />
            <br />
            <li>
              <b>Intellectual Property:</b> All content and materials available
              through our Services, including but not limited to text, graphics,
              logos, and software, are the property of Nudge and are protected
              by intellectual property laws. You may not copy, modify,
              distribute, transmit, display, reproduce, publish, license, or
              create derivative works from our content without our prior written
              consent.
            </li>
            <li>
              <b>User-Generated Content:</b> Our Services allow you to upload,
              store, and transmit content, such as audio and video recordings of
              therapy sessions. You retain ownership of any content you submit
              through our Services. By submitting content, you grant us a
              non-exclusive, worldwide, royalty-free license to use, copy,
              modify, and display the content for the purpose of providing and
              improving our Services.
            </li>
            <li>
              <b>Privacy and Data Protection: </b>We are committed to protecting
              the privacy and security of your personal information and the
              personal health information (PHI) of your clients. Our data
              practices are governed by our Privacy Policy, which is
              incorporated into these Terms. By using our Services, you consent
              to the collection, use, and sharing of your information as
              described in our Privacy Policy.{" "}
            </li>
            <li>
              <b>Disclaimer of Warranties: </b>Our Services are provided on an
              "as-is" and "as-available" basis. We make no representations or
              warranties of any kind, express or implied, regarding the
              availability, reliability, or accuracy of our Services. We
              disclaim all warranties, including but not limited to warranties
              of merchantability, fitness for a particular purpose, and
              non-infringement.
            </li>
            <li>
              <b>Limitation of Liability: </b>To the maximum extent permitted by
              law, Nudge shall not be liable for any indirect, incidental,
              special, consequential, or punitive damages arising out of or
              related to your use of our Services. Our liability for any direct
              damages shall be limited to the fees paid by you for the use of
              our Services during the preceding twelve (12) months.
            </li>
            <li>
              <b>Indemnification: </b>You agree to indemnify, defend, and hold
              harmless Nudge, its affiliates, officers, directors, employees,
              and agents from and against any claims, liabilities, damages,
              losses, costs, or expenses arising out of or in connection with
              your use of our Services or your violation of these Terms.{" "}
            </li>
            <li>
              <b>Termination: </b>We reserve the right to suspend or terminate
              your access to our Services at any time, without notice, for any
              reason, including if we reasonably believe that you have violated
              these Terms. Upon termination, your right to use our Services will
              immediately cease.
            </li>
            <li>
              <b>Modifications to Terms: </b>We reserve the right to modify
              these Terms at any time. Any changes will be effective immediately
              upon posting the revised Terms on our website. Your continued use
              of our Services after the posting of the revised Terms constitutes
              your acceptance of the changes.
            </li>
            <li>
              <b>Contact Us:</b>If you have any questions or concerns about
              these Terms or our Services, please contact us at
              contact@getnudgeai.com
            </li>
          </ol>
          <div>
            By using our Services, you acknowledge that you have read,
            understood, and agree to be bound by these Terms of Service.
          </div>
        </div>
      </div>
    </>
  );
}
