import * as Sentry from "@sentry/react";
import { Drawer, Flex } from "antd";
import queryString from "query-string";
import React from "react";
import { useDispatch } from "react-redux";
import { io, Socket } from "socket.io-client";
import Swal from "sweetalert2";
import CreateClient from "../../components/CreateClient/CreateClient.component";
import CreateNoteModal from "../../components/CreateNoteModal/CreateNoteModal.component";
import CustomiseNote from "../../components/Customise/customise-note.component";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView, useScrollToTop } from "../../hooks/ui-hook";
import { getUserInfo } from "../../service/auth.service";
import { getAllClients, getAllDcmCodes } from "../../service/client.service";
import {
  getCustomizationList,
  getNotesDetails,
  getNotesList,
  getNotesListByClient,
  getNotesListByClientUUID,
  handleCaptureAudioSubmission,
  setTimeOutReached
} from "../../service/notes.service";
import {
  setCreateModalState,
  setCurrentPage,
  setCustomiseSectionState,
  setEditingState,
  setIsDemoPath,
  setLoadingNotesState,
  setSelectedNoteForEditing,
  setShowRecordingView,
  setSubmissionProgressState,
  setUploadProgressState,
  setUploadType,
} from "../../slices/appStateSlice";
import { setUserInfo as setUserInfoApp } from "../../slices/authSlice";
import {
  setClients,
  setCustomizationList,
  setDsm,
} from "../../slices/clientSlice";
import {
  setIsPaused,
  setIsRecording,
  setMediaRecorder,
  setRecordingDetail,
} from "../../slices/recordingsSlice";
import { fetchUserData } from "../../slices/userSlice";
import { AppDispatch } from "../../store";
import { TNotes } from "../../types/index.type";
import { EventType, trackEvent } from "../../utils/analytics";
import { sortClientsByName } from "../../utils/datamanipulation.utils";
import {
  isCheckingAudioStatusForPolling,
  isMaxTimedOut,
  isTooShort,
  stopMediaRecorderAndStopTracks,
} from "../../utils/recording.utils";
import { releaseWakeLock, requestWakeLock } from "../../utils/wakeScreen";
import styles from "./home.module.scss";
import NotesSection from "./NotesSection.component";
import { MAX_RECORDING_TIME_SECONDS } from "../../utils/constants";

const maxRetries = 900;
const retryDelayMs = 1000;

const NotesForMobile = () => {
  const { token } = queryString.parse(window.location.search);

  if (token && localStorage.getItem("token") !== token) {
    localStorage.setItem("token", token as string);
  }

  let chunks: Array<ArrayBuffer> = [];
  const componentName = "home";
  const dispatch = useDispatch<AppDispatch>();
  const isMobileView = useIsMobileView();

  const {
    isCustomiseSectionVisible,
    uploadType,
    selectedClient,
    isCreateModalVisible,
    isCreateClientDrawerVisible,
    currentPage,
  } = useAppSelector((state) => state.appState);

  const { recordMimeType, mediaRecorder, isRecording, recordingDetail } =
    useAppSelector((state) => state.recordings);

  const [notes, setNotes] = React.useState<TNotes[]>([]);
  const [recordingTime, setRecordingTime] = React.useState<number>(0);
  const captureRecordingIdRef = React.useRef("");
  const captureAudioRef = React.useRef<Socket | null>(null);
  const recordingInterval = React.useRef<NodeJS.Timeout | null>(null);
  const [isCaptureEnabled, setIsCaptureEnabled] =
    React.useState<boolean>(false);
  const [isFiltered, setIsFiltered] = React.useState<boolean>(false);

  const isCheckAudioStatus = React.useCallback(
    (note: TNotes) => {
      return (
        note.status != 1 && !isMaxTimedOut(note) && note.error_status === 0
      );
    },
    [isMaxTimedOut]
  );
  
  const scrollToTopNode = useScrollToTop();

  const setMaxTimeOutReached = React.useCallback(
    async (recording: TNotes) => {
      const updateNoteData = {
        audio_id: recording.id,
        error_status: "err_timed_out",
      };
      setTimeOutReached(updateNoteData);
    },
    []
  );

  const checkAudioStatus = React.useCallback(
    async (note: TNotes) => {
      try {
        const response = await getNotesDetails(note.id);
        const data = await response.data;
        setNotes((prev) =>
          prev.map((note) => {
            if (note.id === data.id) {
              return { ...note, ...data };
            } else {
              return note;
            }
          })
        );
        if (isCheckingAudioStatusForPolling(data)) {
          setTimeout(() => checkAudioStatus(data), 3000);
        } else if (note.status !== 1 && isMaxTimedOut(data)) {
          setMaxTimeOutReached(data);
        }
      } catch (error) {
        console.error("Error checking audio status:", error);
      }
    },
    [setMaxTimeOutReached]
  );

  const fetchNotes = React.useCallback(
    async (clientIdOrUuid?: number | string | null, pageNumber?: number) => {
      try {
        let response;
        if (clientIdOrUuid) {
          if (typeof clientIdOrUuid === "string") {
            response = await getNotesListByClientUUID(
              clientIdOrUuid,
              pageNumber
            );
          } else {
            response = await getNotesListByClient(clientIdOrUuid, pageNumber);
          }
        } else if (pageNumber) {
          response = await getNotesList(pageNumber);
        } else {
          response = await getNotesList();
        }
        const data = await response.data;
        setNotes(data?.results);
        dispatch(setLoadingNotesState(false));
        if (pageNumber) {
          dispatch(setCurrentPage(pageNumber));
        }
        for (let note of data?.results) {
          if (isCheckingAudioStatusForPolling(note)) {
            checkAudioStatus(note);
          }
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        dispatch(setLoadingNotesState(false));
      }
    },
    [dispatch, checkAudioStatus]
  );

  const populateStore = () => {
    getAllDcmCodes()
      .then((response) => {
        const dsm = response.data;
        dispatch(setDsm(dsm));
      })
      .catch((error) => {
        console.error("Error fetching DCM codes:", error);
      });
    getCustomizationList()
      .then((response) => {
        const customization = response.data;
        dispatch(setCustomizationList(customization));
      })
      .catch((error) => {
        console.error("Error fetching customization list:", error);
      });
  };

  // biome-ignore lint: no need to add populateStore to the dependency array
  React.useEffect(() => {
    dispatch(setIsDemoPath(false));
    fetchNotes();
    dispatch(fetchUserData());
    populateStore();
  }, []);

  // biome-ignore lint: no need to add scrollToTopNode to the dependency array
  React.useEffect(() => {
    if (selectedClient && selectedClient.id) {
      setNotes([]);
      fetchNotes(selectedClient.id);
      setIsFiltered(true);
    } else if (!selectedClient && isFiltered) {
      setNotes([]);
      fetchNotes();
    }
  }, [selectedClient]);

  const onFetchUserInfo = async () => {
    try {
      const response = await getUserInfo();
      const data = await response.data;
      dispatch(setUserInfoApp(data));
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const initWebsocket = async () => {
    return new Promise((resolve) => {
      const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/ws/notes/`;
      const token = localStorage.getItem("token");
      const socket = io(socketUrl, {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: maxRetries,
        auth: {
          token: token,
        },
      });

      socket.on("connect", () => {
        console.log("socket.io connection established");
        resolve(true);
      });

      socket.on("disconnect", (reason: string) => {
        if (reason === "io server disconnect") {
          console.log("socket.io closed. reconnecting...");
          setTimeout(() => {
            socket.connect();
          }, retryDelayMs);
        } else {
          console.log("socket.io closed cleanly");
        }
      });

      socket.on("connect_error", (error: Error) => {
        if (!socket.active) {
          console.error("socket.io error:", error);
        }
      });

      socket.on("reconnect_failed", () => {
        console.error("Failed to reconnect");
        Swal.fire({
          icon: "error",
          title:
            "Failed to reconnect to the server. Please refresh your browser and try again.",
          text: "Please try again.",
        });
        resetRecordingState();
      });

      socket.on("message", (data: { id: string }) => {
        console.log("Message from server from:", data);
        if (data.id) {
          captureRecordingIdRef.current = data.id;
        }
        setIsCaptureEnabled(true);
      });

      socket.connect();

      // Assign the Socket.IO connection to a ref so it can be used elsewhere in your component
      console.log("assigning the socket.io to the captureaudioref");
      captureAudioRef.current = socket;
    });
  };

  const closeWebsocket = () => {
    captureAudioRef.current?.emit("reset");
    captureAudioRef.current?.disconnect();
  };

  const resetRecordingState = (reload = false) => {
    chunks = [];
    dispatch(setShowRecordingView(false));
    dispatch(setSubmissionProgressState(false));
    dispatch(setMediaRecorder(null));
    if (recordingInterval.current) {
      clearInterval(recordingInterval.current);
      recordingInterval.current = null;
    }
    setRecordingTime(0);
    dispatch(setUploadType(0));
    captureRecordingIdRef.current = "";
    closeWebsocket();
    dispatch(
      setRecordingDetail({
        title: "",
        note_type: 0,
        category_type: 0,
        modality_type: "audio",
        gender_type: 0,
        language_type: 0,
        client_id: null,
      })
    );
    dispatch(setCreateModalState(false));
    dispatch(setEditingState(false));
    dispatch(setSelectedNoteForEditing(null));
    dispatch(setIsRecording(false));
    dispatch(setIsPaused(false));
    if (reload) {
      window.location.reload();
    }
    dispatch(setUploadProgressState(false));
  };

  const displayRecordingTimer = () => {
    // Start a timer to track recording time
    recordingInterval.current = setInterval(() => {
      if (recordingTime >= MAX_RECORDING_TIME_SECONDS) {
        return recordingTime;
      }
      setRecordingTime((time) => time + 1);
    }, 1000);
  };

  React.useEffect(() => {
    if (isRecording && recordingTime >= MAX_RECORDING_TIME_SECONDS) {
      handleStopRecording();
      handleSubmitAudio();
    }
  }, [isRecording, recordingTime]);

  const handleStopRecording = async () => {
    await releaseWakeLock();
    dispatch(setIsRecording(false));
    if (mediaRecorder?.state === "inactive") {
      return;
    }
    trackEvent(EventType.STOP_BUTTON, {
      modalityMode: recordingDetail.modality_type,
    });
    dispatch(setIsPaused(true));
    mediaRecorder?.pause();
    if (recordingInterval.current) {
      clearInterval(recordingInterval.current);
      recordingInterval.current = null;
    }
  };

  const handleSubmitAudio = () => {
    try {
      if (isTooShort(recordingTime)) {
        Swal.fire({
          icon: "error",
          title: "Recording Too Short",
          text: "This recording is too short. Please record for at least 15 seconds, then try again.",
        });
        return;
      }
      dispatch(setSubmissionProgressState(true));
      trackEvent(EventType.SUBMIT_AUDIO_MODAL, {
        modalityMode: recordingDetail.modality_type,
      });
      stopMediaRecorderAndStopTracks(mediaRecorder);
      completeSubmission();
    } catch (error) {
      dispatch(setSubmissionProgressState(false));
      Sentry.captureException(error);
      Swal.fire({
        icon: "error",
        title: "Error submitting audio",
        text: "Please try again or contact support.",
      });
    }
  };

  const fetchClients = () => {
    getAllClients()
      .then((response) => {
        const sortedClients = sortClientsByName(response.data);
        dispatch(setClients(sortedClients));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // biome-ignore lint: no need to add fetchClients to the dependency array
  React.useEffect(() => {
    fetchClients();
    return () => {
      releaseWakeLock();
    };
  }, []);

  /**
   * If media Recorder is not null, add an error event listener to it
   */
  React.useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener("error" as any, (event: any) => {
        Sentry.captureException(event.error);
      });
    }

    return () => {
      if (mediaRecorder) {
        mediaRecorder.removeEventListener("error" as any, () => {
          console.log("error event listener removed");
        });
      }
    };
  }, [mediaRecorder]);

  const completeSubmission = async () => {
    // If we're coming through a capture
    if (uploadType === 0) {
      // One final flush
      console.log(
        "about to call stop of media recorder in complete submission"
      );
      try {
        const formData = new FormData();
        formData.append("audio_id", captureRecordingIdRef.current);
        formData.append("title", recordingDetail.title ?? "Untitled Session");
        formData.append("note_type", recordingDetail.note_type.toString());
        formData.append(
          "category_type",
          recordingDetail.category_type.toString()
        );
        formData.append("content_type", recordMimeType || "audio/webm");
        formData.append("modality_type", recordingDetail.modality_type);
        formData.append(
          "language_type",
          recordingDetail.language_type.toString()
        );
        formData.append("gender_type", recordingDetail.gender_type.toString());
        if (
          recordingDetail.client_id !== undefined &&
          recordingDetail.client_id !== null
        ) {
          formData.append("client_id", recordingDetail.client_id.toString());
        }
        if (
          recordingDetail.location_type !== undefined &&
          recordingDetail.location_type !== null
        ) {
          formData.append(
            "location_type",
            recordingDetail.location_type.toString()
          );
        }
        if (
          recordingDetail.duration_type !== undefined &&
          recordingDetail.duration_type !== null
        )
          formData.append(
            "duration_type",
            recordingDetail.duration_type.toString()
          );
        // Send the audio file to your API endpoint
        const response = await handleCaptureAudioSubmission(formData);
        console.log("File Upload Response:", response);
        if (response.status === 201) {
          trackEvent(EventType.SUBMIT_AUDIO_FINAL, {
            audioFileId: response?.data?.id,
            modalityMode: recordingDetail.modality_type,
          });
          fetchNotes(null, currentPage);
          resetRecordingState(true);
          return;
        } else {
          console.log("Backend Error Response:", response);
          // replace below alet with sweetalert2 alert
          Swal.fire({
            icon: "error",
            title: "Audio upload and processing failed.",
            text: "Please try again.",
          });
          fetchNotes(null, currentPage);
          resetRecordingState();
          return;
        }
      } catch (error) {
        console.error("Upload Error:", error);
        // replace below alet with sweetalert2 alert
        Swal.fire({
          icon: "error",
          title: "Audio upload and processing failed.",
          text: "Please try again.",
        });
        fetchNotes(null, currentPage);
        resetRecordingState();
        return;
      }
    }
  };

  React.useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        console.log("Page is now in the foreground!");
        await requestWakeLock();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // biome-ignore lint: no need to add onFetchUserInfo to the dependency array
  React.useEffect(() => {
    if (notes && notes.length > 0) {
      const token = localStorage.getItem("token");
      if (token) {
        onFetchUserInfo();
      }
    }
  }, [notes]);

  return (
    <>
      <Drawer
        title="Customize"
        open={isCustomiseSectionVisible}
        placement={isMobileView ? "bottom" : "right"}
        height={isMobileView ? "85%" : "100%"}
        closable={true}
        destroyOnClose
        onClose={() => dispatch(setCustomiseSectionState(false))}
      >
        <Flex
          vertical
          justify="space-between"
          style={{
            height: "100%",
          }}
        >
          <Flex
            className={`${styles[`${componentName}__customise_clearance`]}`}
          >
            <span></span>
          </Flex>
          <CustomiseNote noPadding={true} />
        </Flex>
      </Drawer>
      {isCreateClientDrawerVisible && <CreateClient />}
      {isCreateModalVisible && (
        <CreateNoteModal
          chunks={chunks}
          captureRecordingIdRef={captureRecordingIdRef}
          captureAudioRef={captureAudioRef}
          isCaptureEnabled={isCaptureEnabled}
          resetRecordingState={resetRecordingState}
          initWebsocket={initWebsocket}
          setRecordingTime={setRecordingTime}
          displayRecordingTimer={displayRecordingTimer}
        />
      )}
      <div
        style={{
          padding: "10px",
        }}
      >
        <NotesSection
          notes={notes}
          setNotes={setNotes}
          fetchNotes={fetchNotes}
          resetRecordingState={resetRecordingState}
        />
      </div>
    </>
  );
};

export default NotesForMobile;
